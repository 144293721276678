import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Route, Switch,} from "react-router-dom";
import './App.css';
import Campaign from "./Campaign";


let CampaignContext = React.createContext({});

function App() {
    let [my_campaigns, setMyCampaigns] = useState();
    useEffect(() => {
        let data = window.localStorage.getItem('my_campaigns');
        let campaigns = JSON.parse(data);
        // console.log(
        //     'my_campaigns',
        //     campaigns,
        //     typeof (campaigns)
        // );
        setMyCampaigns(() => campaigns);
    }, []);

    return (
        <div className="app">
            <div className="content">
                {/*<CampaignContext.Provider value={{}}>*/}
                <Router>
                    <Switch>
                        <Route exact path="/">
                            {my_campaigns ?
                                Object.entries(my_campaigns).map(([key, value]) => {
                                    return <div><Link to={`/campaigns/${key}`}>{value}</Link></div>
                                })
                                :<p>Nothing to see here... move along!{my_campaigns}</p>
                            }
                        </Route>
                        <Route path="/campaigns/:campaign" component={Campaign}/>
                        <Route>404, so sad :(</Route>
                    </Switch>
                </Router>
                {/*</CampaignContext.Provider>*/}
            </div>
        </div>
    );
}

export {CampaignContext};
export default App;
