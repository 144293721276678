import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {SpinnerComponent} from "react-element-spinner";

const weekdays = ['M', 'T', 'O', 'T', 'F', 'L', 'S'];
const NO = 0;
const YES = 1;

// const MAYBE = 2;

function sameState(a, b) {
    if (!b) {
        return a === b;
    }
    return a.every((e, i) => e === b[i]);
}

function loadCampaign(campaign, year, month, player, setLoading, setCampaign, setState, setError) {

    return () => {
        let abortController = new AbortController();
        let signal = abortController.signal;

        fetch(`/api/campaigns/${campaign}`, {signal})
            .then(response => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                setLoading(false);
                setCampaign(json);
                let storedYear = json && json['preferences'][year];
                let storedMonth = storedYear && storedYear[month];
                let storedState = storedMonth && storedMonth[player];

                storedState && setState(storedState);
            })
            .catch(err => {
                setError('Failed to fetch campaign.' + err.message);
                setLoading(false);
            });

        return () => {
            abortController.abort();
        }
    }
}

function Foobar() {
    // let [year, month] = useParams();
    let {campaign: campaign_id, year, month, player} = useParams();
    year = parseInt(year);
    month = parseInt(month);
    let today = new Date(year, month - 1, 10);
    let prevMonth = new Date(today);
    prevMonth.setMonth(today.getMonth() - 1);
    let nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);

    let daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    let firstInMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let numFillers = (firstInMonth.getDay() + 6) % 7;  // Sunday = 0
    let [isSaving, setSaving] = useState(false);
    let [isLoading, setLoading] = useState(true);
    let [campaign, setCampaign] = useState();
    let initialState = [...new Array(daysInMonth).fill(NO)];
    let [state, setState] = useState(initialState);
    let [error, setError] = useState(null);

    useEffect(loadCampaign(campaign_id, year, month, player, setLoading, setCampaign, setState, setError), []);

    let storedYear = campaign && campaign['preferences'][year];
    let storedMonth = storedYear && storedYear[month];
    let storedState = storedMonth && storedMonth[player];
    let hasChanges = !sameState(state, storedState || initialState);

    function save(days) {
        setSaving(true);
        // TODO URL encode name?
        let fixedDays = days.map(n => n || 0);
        fetch(`/api/campaigns/${campaign_id}/${year}/${month}/${player}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(fixedDays)
        }).then(response => {
            setSaving(false);
            if (response.status !== 204) {
                throw response;
            }

            setCampaign(campaign => {
                let newCampaign = {...campaign};
                newCampaign['preferences'][year] = newCampaign['preferences'][year] || {};
                newCampaign['preferences'][year][month] = newCampaign['preferences'][year][month] || {};
                newCampaign['preferences'][year][month][player] = fixedDays;
                return newCampaign;
            })
        }).catch(err => {
            setSaving(false);
            setError('Failed to save campaign');
        })
    }

    function toggleDate(day, direction = 1) {
        setState(state => {
            let newState = [...state];
            newState[day] = ((state[day] || 0) + direction + 3) % 3;
            return newState;
        })
    }

    function toggleWeekDay(day, direction = 1) {
        // not as good here... we want to 'undo'
        let sameWeekDay = [...fillers, ...days].filter((e, i) => {
            return typeof e !== "undefined" && (i % 7) === day;
        });
        let allSame = new Set(sameWeekDay.map(day => state[day])).size === 1
        if (allSame) {
            sameWeekDay.forEach(day => toggleDate(day, direction))
        } else {
            setState(state => state.map((sign, i) => sameWeekDay.includes(i) ? YES : sign))
        }
    }

    function classForState(s) {
        if (s === 1) {
            return "select-yes";
        } else if (s === 2) {
            return "select-maybe";
        } else {
            return "select-no";
        }
    }

    let fillers = [...new Array(numFillers)];
    let days = [...new Array(daysInMonth).keys()];
    let counter = 0;
    let history = useHistory();
    const monthName = today.toLocaleString('default', { month: 'long' });

    return <>
        <SpinnerComponent loading={isSaving || isLoading} position="centered"/>
        <h1>{campaign && `${campaign['meta']['description']}`}</h1>
        <div className="calendar">
            {weekdays.map((name, day) =>
                <div
                    key={`weekday-${day}`}
                    className={`calendar-item weekday day-${counter++ % 7}`}
                    onClick={() => toggleWeekDay(day)}
                    onContextMenu={e => {
                        toggleWeekDay(day, -1);
                        e.preventDefault();
                    }}
                >{name}</div>)}
            {fillers.map((_, i) =>
                <div
                    key={`filler-${i}`}
                    className={`calendar-item day-${counter++ % 7}`}
                />)}
            {days.map(day =>
                <div
                    key={`day=${day}`}
                    className={`calendar-item ${classForState(state[day])} day-${counter++ % 7}`}
                    onClick={() => toggleDate(day)}
                    onContextMenu={e => {
                        toggleDate(day, -1)
                        e.preventDefault();
                    }}
                >{day + 1}</div>)}
        </div>

        <div className={"align-right"}>
            <div className={"whosediting"}>{player}, {monthName}</div>
            <div className={"toolbar"}>
                <button
                    onClick={() => history.push(`/campaigns/${campaign_id}/${prevMonth.getFullYear()}/${prevMonth.getMonth() < 9 ? '0' : ''}${prevMonth.getMonth() + 1}/edit/${player}`)}
                    disabled={hasChanges} type="button">{"<<"}</button>
                <button disabled={!hasChanges} onClick={() => save(state)}>Save</button>
                <button
                    onClick={() => history.push(`/campaigns/${campaign_id}/`)}
                    disabled={hasChanges} type="button">Done
                </button>
                <button
                    onClick={() => history.push(`/campaigns/${campaign_id}/${nextMonth.getFullYear()}/${nextMonth.getMonth() < 9 ? '0' : ''}${nextMonth.getMonth() + 1}/edit/${player}`)}
                    disabled={hasChanges} type="button">{">>"}</button>
            </div>
        </div>
        {error && <p>Something broke: {error}</p>}
    </>
}

export default Foobar;